import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { BalanceService } from './balance.service';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { ParamServicesService } from './param-services.service';
import { UiServices } from './ui-services';

@Injectable({
  providedIn: 'root',
})
export class CartServiceService {
  private _domainId = environment.domainId;
  private _total: any = 0;
  public marketplaceFilers: any = {
    domainId: this._domainId,
    catalogue: '',
    pag: 1,
    search: '',
    category: '',
    orden: '',
    promo: '',
    from: '',
    upTo: '',
    sponsor: '',
    services: '',
    subscription: '',
  };

  public cart: any[] = [];
  public totalQuantity = 0;
  public totalSrc = new Subject();
  public totalSrcObs = this.totalSrc.asObservable();
  public logined = false;
  public dist = undefined;
  public client = undefined;
  public selectedBranchId = undefined;

  get total() {
    return this._total;
  }

  set total(value) {
    this._total = value;
    this.total$.next(this.total);
  }

  public total$ = new BehaviorSubject<number>(this.total);

  constructor(
    private storageService: StorageService,
    private uiServices: UiServices,
    private httpSv: HttpService,
    private loginSv: LoginService,
    private balanceSv: BalanceService,
    private paramSv: ParamServicesService,
    private trSv: TranslateService,
    private navCtrl: NavController
  ) {
    this.selectedBranchId = Number(
      this.storageService.getLocalStorage('branchContactId')
    );
  }

  checkCart() {
    this.logined = localStorage.getItem('logined') === '1' ? true : false;
    this.setRoll();
    this.storageService.saveCartObs.subscribe((item) => {
      if (item === 'logout') {
        this.total = 0;
        return true;
      }
      this.logined = true;
      this.getCart(false);
    });
    this.getCart(false);
  }

  setRoll() {
    const subs = this.loginSv.isLogined.subscribe(() => {
      const rollId = this.loginSv.user?.rolId || undefined;
      this.dist = rollId === 7;
      this.client = rollId !== 7;
      // this.dist =
      //   rollId === 7 || (this.paramSv.existDistributorSignUp && rollId === 3);
      // this.client =
      //   (rollId !== 7 && rollId !== 3) ||
      //   (rollId === 3 && !this.paramSv.existDistributorSignUp);
      subs.unsubscribe();
    });
  }

  clearFilter() {
    for (const key in this.marketplaceFilers) {
      if (key === 'pag') {
        this.marketplaceFilers[key] = 1;
      } else if (key === 'domainId') {
        this.marketplaceFilers[key] = this._domainId;
      } else {
        this.marketplaceFilers[key] = '';
      }
    }
  }

  async compareCart(emitLength) {
    return new Promise<any[]>(async (resolve) => {
      if (!this.logined) {
        await this.fillCart();
        this.cart = this.cart.map((item) => {
          if (item.isActive) {
            item.isChecked = true;
          } else if (item.meetingId) {
            item.isChecked = true;
          }
          return item;
        });
        resolve(this.cart);
      } else {
        this.httpSv.loadingIndicatorSrc.next(true);
        const res: any = await this.httpSv.itemIndex('cart');
        let savedCart: any[] = res.data;
        savedCart = savedCart.filter(
          (item) => item.productId || item.meetingId || item.subscriptionId
        );
        setTimeout(async () => {
          savedCart = this.activeProduct(savedCart);
          if (this.paramSv.existDistributorSignUp) {
            savedCart = this.activeProduct(savedCart);
          } else {
            const subs = this.paramSv.emitImagesObs.subscribe((item: any) => {
              if (item.name === 'distributor' && this.dist) {
                savedCart = this.activeProduct(savedCart);
                subs.unsubscribe();
              }
            });
          }
          await this.storageService.set('cart', savedCart);
          await this.getCart(emitLength);
          this.httpSv.loadingIndicatorSrc.next(false);
          this.cart = savedCart;
          resolve(savedCart);
        });
      }
    });
  }

  activeProduct(savedCart) {
    const dist = this.dist;
    savedCart.map((item) => {
      let comparePriceDist =
        item.product?.promotionalPriceWSale || item.product?.priceWSale;
      let comparePriceClient =
        item.product?.promotionalPrice || item.product?.price;

      let productPrice = JSON.parse(JSON.stringify(item.price));
      if (item?.product && item.productId) {
        if (item.cartProductOption?.length) {
          for (const option of item.cartProductOption) {
            comparePriceDist && (comparePriceDist += option.price);
            comparePriceClient && (comparePriceClient += option.price);
          }
        }
        if (dist) {
          if (
            (item.product.existenceWSale === 0 ||
              item.product.existence === 0) &&
            !item.product.countHidden
          ) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
          const comparePrice = comparePriceDist || comparePriceClient;
          this.comparePrices(item, comparePrice);
          // if((comparePriceDist && item.price !== comparePriceDist) || (comparePriceClient && item.price !== comparePriceClient)){
          //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency?.symbol || '$'}`;
          //   item.price = comparePriceDist || comparePriceClient;
          //   item.changedPrice = true;
          // }
        } else {
          if (item.product.existence === 0 && !item.product.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
          const data = {
            price: JSON.parse(JSON.stringify(item.price)),
          };

          if (comparePriceClient === productPrice) {
            console.log('data :>> ', data);
          }
          this.comparePrices(item, comparePriceClient);
          // if(comparePriceClient && item.price !== comparePriceClient){
          //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency.symbol}`;
          //   item.price = comparePriceClient;
          //   item.changedPrice = true;
          // }
        }
      } else if (item.subscriptionId) {
        item.isActive = 1;
        this.comparePrices(item, comparePriceClient);
        // if(comparePriceClient && item.price !== comparePriceClient){
        //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency.symbol}`;
        //   item.price = comparePriceClient;
        //   item.changedPrice = true;
        // }
      } else {
        if (item.existence === 0 && !item.countHidden) {
          item.isActive = 0;
          this.noExistenceMessage(item);
        } else {
          item.isActive = 1;
        }
      }
      if (item.isActive) item.isChecked = true;
      else if (item.meetingId) {
        item.isChecked = true;
        item.isActive = 1;
      }
    });
    return savedCart;
  }

  noExistenceMessage(item) {
    item.noExistence =
      'El producto no esta disponible en estos momentos. No será registrado en tu compra';
  }

  comparePrices(item, comparePrice) {
    if (comparePrice && item.price !== comparePrice) {
      const msg = this.trSv.instant('EL_PRECIO_HA_CAMBIADO_PRECIO_ANTERIOR');
      item.message = `${msg}: ${item.price} ${
        this.balanceSv.currency?.symbol || 'USD'
      }`;
      item.price = item?.product?.price_tax || comparePrice;
      item.changedPrice = true;
    }
  }

  fillCart() {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        this.cart = (await this.storageService.get('cart')) || [];
        resolve(this.cart);
      }, 100);
    });
  }

  async setCart(product?) {
    await this.fillCart();
    if (!product) {
      return;
    }
    if (!this.logined) {
      let compareSaved = undefined;
      if (product[0]?.additionals) {
        compareSaved = JSON.stringify(product[0]?.additionals);
      }

      this.cart = this.cart.filter((cartProduct) => {
        if (cartProduct.id !== product[0]?.id && !compareSaved) {
          return cartProduct;
        } else if (cartProduct.id === product[0]?.id && !product[0]?.same) {
          return cartProduct;
        } else if (cartProduct.id === product[0]?.id && !this.logined) {
          const itemAdd = JSON.stringify(cartProduct.additionals);
          if (itemAdd !== compareSaved) {
            return cartProduct;
          }
        }
      });

      // this.cart = this.cart.filter(async item => {
      //   const { same } = await this.verifyIsSameProduct(item, this.cart, false);
      // });
    } else {
      this.cart = [];
    }
    this.cart.push(...product);
    const rolId = this.loginSv?.user?.rolId;
    const dist =
      rolId === 7 || (this.paramSv.existDistributorSignUp && rolId === 3);
    this.cart.map((item) => {
      if (item.product && item.productId && rolId) {
        if (dist) {
          if (item.existenceWSale === 0 && !item.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
        } else {
          if (item.existence === 0 && !item.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
        }
      } else {
        if (item.existence === 0 && !item.countHidden) {
          item.isActive = 0;
          this.noExistenceMessage(item);
        } else {
          item.isActive = 1;
        }
      }
    });
    await this.storageService.set('cart', this.cart);
    return true;
  }

  setLength(emit = true) {
    setTimeout(() => {
      this.total = 0;
      this.totalQuantity = 0;
      let cart = this.cart;
      const branchId = this.selectedBranchId || this.loginSv.branchContactId;
      if (branchId) {
        cart = this.cart.filter((x) => x.branchContactId === branchId);
      }
      console.log('cart :>> ', cart);
      cart?.map((item) => {
        if (item?.productId || item?.id) {
          this.total += item.quantity;
          let totalQuantityProduct = 0;
          if (item.subscriptionId) {
            if (item.product?.includesDues || item.includesDues) {
              const inscriptionPrice =
                item.product?.inscriptionPrice || item.inscriptionPrice;

              const price =
                item.product?.promotionalPrice ||
                item.product?.price ||
                item?.promotionalPrice ||
                item?.price;

              totalQuantityProduct +=
                (inscriptionPrice + price) * item.quantity;
            } else {
              const inscriptionPrice =
                item.product?.inscriptionPrice || item.inscriptionPrice;

              const price =
                item.product?.promotionalPrice ||
                item.product?.price ||
                item?.promotionalPrice ||
                item?.price;

              totalQuantityProduct +=
                (inscriptionPrice || price) * item.quantity;
            }
          } else {
            totalQuantityProduct +=
              (item.product?.priceTax || item.price) * item.quantity;
          }
          item.isActive && (this.totalQuantity += totalQuantityProduct);
        }
      });

      if (this.total > 99) {
        this.total = '99+';
      }

      this.totalSrc.next({ emit, total: this._total });
    }, 100);
  }

  async findValue(id) {
    await this.fillCart();
    let product = undefined;
    if (!this.cart) return null;
    if (!this.logined) {
      product = this.cart?.filter((x) => {
        if (
          (x.serviceId && x.serviceId === id) ||
          (x.subscriptionId && x.subscriptionId === id) ||
          (x.id && x.id === id)
        ) {
          return x;
        }
      });
    } else {
      product = this.cart?.filter((x) => {
        if (
          (x.serviceId && x.serviceId === id) ||
          (x.subscriptionId && x.subscriptionId === id) ||
          (x.productId && x.productId === id)
        ) {
          return x;
        }
      });
    }
    return product;
  }

  async countProducts(op: string, cantidad: any) {
    if (op === 'sum') {
      cantidad++;
    } else if (op === 'rest' && cantidad > 0) {
      cantidad--;
    }
    return { cantidad };
  }

  async addCart(comingProduct, doAdd = true, isDistributor?) {
    try {
      // CHEQUEAR SI EXISTE UN SUBSCRIPTION ID EN EL CARRITO
      if (!!this.cart.find((product) => product.subscriptionId) && doAdd) {
        return this.uiServices.presentAlert(
          'No puedes agregar otro item al carrito hasta pagar la suscripción agregada.'
        );
      }
      //
      let product = { ...comingProduct };
      let isSubscription = false;
      await this.uiServices.showLoading();
      let media = undefined;
      if (product.media) {
        media =
          typeof product.media === 'string'
            ? product?.media
            : product?.media[0]?.file;
      }
      if ('inscriptionPrice' in product) {
        product.subscriptionId = product.id;
        let subscriptionPrice = 0;
        if (true) {
          subscriptionPrice =
            product.inscriptionPrice +
            (product.promotionalPrice || product.price);
        } else {
          subscriptionPrice =
            product.inscriptionPrice ||
            product.promotionalPrice ||
            product.price;
        }

        product.price = subscriptionPrice;
        isSubscription = true;
      } else {
        !product.productId && (product.productId = product.id);
      }
      const findProduct: any = await this.findValue(product.id);
      if ((findProduct && doAdd) || !this.logined) {
        const { detail, quantity } = await this.verifyIsSameProduct(
          product,
          findProduct,
          doAdd
        );
        product.quantity = quantity;
        product.detail = detail;
      }

      const saveProduct = {
        ...product,
      };

      saveProduct.media = media;

      if (isDistributor) {
        saveProduct?.priceWSale && (saveProduct.price = saveProduct.priceWSale);
        if (saveProduct?.promotionalPriceWSale) {
          saveProduct.price = saveProduct.promotionalPriceWSale;
        }
      } else if (!('inscriptionPrice' in product)) {
        saveProduct?.promotionalPrice &&
          (saveProduct.price = saveProduct.promotionalPrice);
      }

      if (findProduct && this.logined && !doAdd && !isSubscription) {
        delete saveProduct.id;
      } else {
        delete saveProduct.productId;
      }
      let data = [saveProduct];
      if (this.logined) {
        const res: any = await this.httpSv.itemAction(
          saveProduct,
          'cart',
          'update'
        );

        if (res.message) {
          await this.uiServices.loading.dismiss();
          await this.uiServices.presentAlert(res.message);
          return;
        }
        data = res.data;
      }

      if (doAdd && !isSubscription) {
        delete saveProduct.productId;
      }

      await this.setCart(data);

      this.activeProduct(this.cart);
      this.setLength();
      await this.uiServices.loading.dismiss();
      return product;
    } catch (err) {
      console.error(err);
      await this.uiServices.loading.dismiss();
    }
  }

  async getCart(emit?) {
    try {
      const cart = await this.fillCart();
      const res: any = await this.httpSv.itemAction(
        { items: cart },
        'cart',
        'store'
      );
      if (res.message) {
        await this.uiServices?.loading?.dismiss();
        await this.uiServices.presentAlert(res.message);
        return;
      }
      await this.saveOptions(cart);
      await this.setCart(res.data);
      this.setLength(emit);
    } catch (err) {
      console.error(err);
    }
  }

  async saveOptions(cart) {
    cart.forEach((product) => {
      const additionals = this.addOptions(product, undefined, true);
      product.cartProductOption = additionals;
    });
    await this.saveCart(cart, 0);
  }

  buildCartMessage(list: any[]) {
    let cart = '';
    list.forEach((producto, index) => {
      if (producto.isChecked) {
        if (!producto.meetingId) {
          const msgPr = this.trSv.instant('NOMBRE_DEL_PRODUCTO');
          const quantity = this.trSv.instant('CANTIDAD');
          const price = this.trSv.instant('PRECIO');
          const add = this.trSv.instant('ADICIONAL');
          const totalAddsPrice = producto?.cartProductOption?.reduce(
            (acc, current) => acc + current.price,
            0
          );
          const subtotal = producto.price - totalAddsPrice || 0;
          cart += `${index + 1} - ${msgPr}: ${producto.name}%0A${quantity}: (${
            producto.quantity
          })%0ASubtotal: $${subtotal}%0A${price}: $${producto.price}%0A%0A`;
          for (const additional of producto.cartProductOption) {
            cart += `${add}: ${additional.name}%0A${price}: $${
              additional.price || 0
            }%0A%0A`;
          }
        } else if (producto.meetingId) {
          const meridianInit =
            Number(producto.meeting.startHour.split(':')[0]) < 12 ? 'am' : 'pm';
          const meridianEnd =
            Number(producto.meeting.endHour.split(':')[0]) < 12 ? 'am' : 'pm';
          cart += `Nombre del Servicio: ${producto.name}%0APrecio: ${producto.price}%0AFecha: ${producto.meeting.date}%0AInicio: ${producto.meeting.startHour} ${meridianInit}%0ATermina: ${producto.meeting.endHour} ${meridianEnd}%0A%0A`;
        }
      }
    });
    return cart;
  }

  addOptions(product, findProduct, check) {
    let data = [];
    if (product.additionals) {
      product.additionals?.forEach((item) => {
        data.push(...item.checkedAdds);
      });
    } else if (product.cartProductOption) {
      data = [...product.cartProductOption];
    }
    const productOptions = [...data];
    // if(findProduct && findProduct.cartProductOption){
    //   findProduct.cartProductOption = findProduct.cartProductOption.map(item => item.name);
    // }else
    if (!this.logined && !check) {
      const data = [];
      findProduct.additionals?.forEach((item) => {
        data.push(...item.checkedAdds);
      });
      findProduct.cartProductOption = [...data];
      // findProduct.cartProductOption = data.map(item => {
      //   if(item.name) return item.name;
      //   else return item;
      // });
    }
    return productOptions;
  }

  async verifyIsSameProduct(product, findProducts: any[], doAdd) {
    let quantity = product.quantity;
    let detail = product.detail;
    let same = false;

    for (const findProduct of findProducts) {
      const productOptions = this.addOptions(product, findProduct, false);
      const compareSaved =
        JSON.stringify(findProduct.cartProductOption) || '[]';
      const compareProduct = JSON.stringify(productOptions) || '[]';
      if (compareSaved === compareProduct) {
        if (
          !this.paramSv.dispatch.branch ||
          (this.paramSv.dispatch.branch &&
            findProduct.branchContactId === product.branchContactId)
        ) {
          product.same = true;
          same = true;
          if (findProduct?.product?.frequency) {
            quantity = 1;
          } else {
            if (doAdd) quantity += findProduct.quantity;
            // quantity += 1;
          }
          detail && (product.detail += findProduct.detail);
          break;
        } else {
          product.same = false;
        }
      } else {
        product.same = false;
      }
    }
    return { quantity, detail, same };
  }

  async deleteCart(cartId) {
    try {
      const res: any = await this.httpSv.itemAction(
        { cartId },
        'cart',
        'destroyCart'
      );
      await this.setCart(res.data);
      this.setLength();
    } catch (err) {
      console.error(err);
    }
  }

  async saveCart(cart, item) {
    await this.storageService.set('cart', cart);
    this.cart = cart;
    return true;
  }

  async favoriteControl(productId, action) {
    try {
      const res = await this.httpSv.itemAction(
        { productId },
        'productFavorite',
        action
      );
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async agendDate(date) {
    try {
      const res: any = await this.httpSv.itemAction(date, 'cart', 'update');
      await this.setCart(res.data);
      await this.activeProduct(this.cart);
      this.setLength();
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async verifyExistMeet() {
    try {
      const meetings = this.cart.filter((item) => item.meetingId);
      const body = {
        ids: meetings.map((item) => item.meetingId),
      };
      const res = await this.httpSv.itemAction(body, 'cart', 'isAvailable');
      const errors: any[] = res.data;
      if (errors.length) {
        const trSv = this.trSv.instant('LOS_SERVICIOS');
        let errorsMsg = `${trSv}: `;
        for (const meet of errors) {
          const meetingsError = this.cart.find(
            (item) => item.meetingId === meet
          );
          errorsMsg += `${meetingsError.name}\n\n`;
          const id = meetingsError.id;
          const cartId = meetingsError.cartId;
          await this.httpSv.itemAction({ id, cartId }, 'cart', 'destroy');
        }
        await this.compareCart(false);
        const trPr = this.trSv.instant(
          'NO_PUEDE_PROCESARSE_DEBIDO_QUE_LOS_HORARIOS_SELECCIONADOS_YA_FUERON_OCUPADOS_POR_FAVOR_SELECCIONA_OTROS_HORARIOS'
        );
        errorsMsg += trPr;
        await this.uiServices.presentAlert(errorsMsg, false);
        this.marketplaceFilers.services = 1;
        return false;
      } else {
        return true;
      }
    } catch (err) {}
  }

  calcPriceOptions(data, ev) {
    const restPrice = ev.restPrice;
    const addPrice = ev.price;

    if (data.price) {
      data.price -= restPrice;
      data.price += addPrice;

      data.visiblePrice -= restPrice;
      data.visiblePrice += addPrice;
    }

    if (data.promotionalPrice) {
      data.promotionalPrice -= restPrice;
      data.promotionalPrice += addPrice;

      data.visiblePromoPrice -= restPrice;
      data.visiblePromoPrice += addPrice;
    }

    if (data.priceWSale) {
      data.priceWSale -= restPrice;
      data.priceWSale += addPrice;

      data.visiblePriceWSale -= restPrice;
      data.visiblePriceWSale += addPrice;
    }

    if (data.promotionalPriceWSale) {
      data.promotionalPriceWSale -= restPrice;
      data.promotionalPriceWSale += addPrice;

      data.visiblePromotionalPriceWSale -= restPrice;
      data.visiblePromotionalPriceWSale += addPrice;
    }
  }

  verifyFavorite(data) {
    return data.favorite ? 'heart' : 'heart-outline';
  }

  async addFavorite(data) {
    try {
      if (!this.loginSv.user) {
        const txt = this.trSv.instant(
          'DEBES_INICIAR_SESION_PARA_AGREGAR_PRODUCTOS_A_FAVORITOS'
        );
        const { role } = await await this.uiServices.presentAlert(txt, true);
        if (role === 'accept') {
          await this.navCtrl.navigateRoot('auth');
        }
        return { favoriteIcon: 'heart-outline', favorite: 0 };
      } else {
        let action = '';
        data.favorite ? (action = 'destroy ') : (action = 'store');
        await this.favoriteControl(data.id, action);
        const favoriteIcon = action === 'store' ? 'heart' : 'heart-outline';
        const favorite = action === 'store' ? 1 : 0;
        return { favoriteIcon, favorite };
      }
    } catch (err) {
      console.error(err);
    }
  }

  async doSearch(search) {
    try {
      this.marketplaceFilers.search = search;
      this.marketplaceFilers.pag = 1;
      await this.navCtrl.navigateForward('marketplace', {
        queryParams: this.marketplaceFilers,
      });
    } catch (err) {
      console.error(err);
    }
  }
}
